.mainContent {
  width: 850px;
  position: absolute;
  right: 0;
  bottom: 20px;
  top: 100px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 4px;
  pointer-events: all;
  transition: right 2s ease;
  border-radius: 4px 0 0 4px;
}
.mainContent .head {
  line-height: 32px;
  font-size: 18px;
  flex-shrink: 0;
  color: #FFF;
}
.mainContent .head .attach {
  width: 730px;
  float: right;
}
.mainContent .blockTitle {
  font-size: 14px;
  margin-left: 6px;
  border-bottom: 1px solid #FFF;
  color: white;
}
.left_click_btn {
  position: absolute;
  top: 48%;
  left: -18px;
  background: var(--themeColor);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 0px;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
}
.left_click_btn img {
  width: 18px;
  transform: rotate(180deg);
}
.left_click_btn_active {
  transition: all 2s ease;
}
.left_click_btn_active img {
  transform: rotate(0deg);
}
.mainContent2,
.index {
  position: absolute;
  right: 0;
  top: 60px;
  height: calc(100vh - 60px);
  width: 750px;
  background: rgba(255, 255, 255, 0.9);
  pointer-events: all;
  transition: right 1s ease;
}
.mainContent2 .mian_title,
.index .mian_title {
  font-size: 18px;
  width: 100%;
  padding: 10px 10px;
  display: flex;
  font-weight: 600;
  position: relative;
  margin: 0 0 0 10px;
  line-height: 18px;
}
.mainContent2 .mian_title:before,
.index .mian_title:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 18px;
  top: 11px;
  background: #4261ED;
  left: 1px;
}
.mainContent2 .gary_line,
.index .gary_line {
  width: calc(100% - 20px);
  height: 2px;
  position: relative;
  left: 10px;
  bottom: 0;
  background-color: rgb(247 247 250);
}
.mainContent2 .gary_line::after,
.index .gary_line::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  top: 0px;
  background: #e0e2e3;
  right: 0;
}
.mainContent2 .gary_line::before,
.index .gary_line::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  top: 0;
  background: #e0e2e3;
  left: 0;
}
.header-bg-v2 {
  height: 40px;
  line-height: 30px;
  padding-left: 10px;
  display: -webkit-flex;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
}
.el-table .cell {
  white-space: pre-line;
}
.el-table {
  margin-bottom: 4px;
  height: calc(100% - 40px);
}
#app {
  min-width: 1366px;
  height: 100%;
}
.el-table th {
  background-color: #103c56;
}
.el-table__footer-wrapper thead div,
.el-table__header-wrapper thead div {
  background-color: #103c56;
  color: white;
}
.el-pager li.active {
  background-color: #103c56;
  border-color: #103c56;
}
.el-table__body-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
}
.Wraning {
  color: red;
}
.isshow {
  display: none;
}
.ivu-table-overflowX::-webkit-scrollbar-thumb {
  background-color: #103c56;
}
.mytable::-webkit-scrollbar-thumb {
  background-color: #103c56;
}
.ivu-checkbox-checked .ivu-checkbox-inner {
  border-color: #103c56 !important;
  background-color: #103c56 !important;
}
.ivu-checkbox-indeterminate .ivu-checkbox-inner {
  border-color: #103c56 !important;
  background-color: #103c56 !important;
}
.ivu-page-item:hover {
  color: white !important;
  border-color: #103c56 !important;
  background-color: #103c56 !important;
}
.ivu-page-item {
  border-color: #103c56 !important;
}
.ivu-page-item-active a,
.ivu-page-item-active:hover a {
  color: white !important;
}
.ivu-page-item:hover a {
  color: white !important;
}
.ivu-page-item-active {
  color: white !important;
  border-color: #103c56 !important;
  background-color: #103c56 !important;
}
.ivu-table th {
  height: 40px;
  white-space: nowrap;
  overflow: hidden;
  background-color: transparent;
  color: white;
}
.ivu-table td {
  background-color: transparent;
}
.ivu-table-filter i.on {
  color: dodgerblue;
}
.ivu-table-filter i {
  color: white;
}
.ivu-page-next:hover,
.ivu-page-prev:hover {
  border-color: #103c56;
}
.ivu-table-cell .ivu-date-picker {
  color: white;
}
.ivu-table-wrapper {
  border: none;
}
.Epititle {
  color: white;
  padding: 0 15px;
  line-height: 40px;
  border-bottom: 1px solid white;
}
.facelist {
  height: 100%;
  font-size: 14px;
}
.facebox {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: 1px solid #103c56;
  overflow: hidden;
}
.facebox img {
  width: 100%;
  display: block;
  margin: 0 auto;
}
.activebox {
  animation: mybig infinite 2s;
  box-shadow: 0 1px 4px 2px red;
}
@keyframes mybig {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
.content-title {
  font-size: 14px;
  color: white;
}
.right-content {
  width: 98%;
  margin: 0 auto;
  background-color: rgba(2, 30, 43, 0.2);
  border: 1px solid #226B92;
  border-radius: 5px;
  padding: 10px;
}
.right-content:after {
  content: '';
  display: block;
  clear: both;
}
.right-content > div {
  float: left;
  display: block;
}
.faceRow {
  width: 100%;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
}
.faceRow > div {
  width: 70px;
  margin: 0 10px;
  padding: 5px;
  height: 100px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}
.active {
  background: rgba(38, 121, 163, 0.6);
  border: 1px solid rgba(38, 121, 163, 0.9);
}
