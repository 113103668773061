
@import "../../variables.less";
@import "../../resource/common.less";
@currentWidth: 850px;
@attachWidth: 730px;
.header-bg-v2 {
	height: 40px;
	line-height: 30px;
	padding-left: 10px;
	display: -webkit-flex;
	display: flex;
	font-size: 16px;
	justify-content: space-between;
}

.el-table .cell {
	white-space: pre-line;
}

.el-table {
	margin-bottom: 4px;
	height: calc(100% - 40px);
}

#app {
	min-width: 1366px;
	height: 100%;
}

.el-table th {
	background-color: #103c56;
}

.el-table__footer-wrapper thead div, .el-table__header-wrapper thead div {
	background-color: #103c56;
	color: white;
}

.el-pager li.active {
	background-color: #103c56;
	border-color: #103c56;
}

.el-table__body-wrapper {
	overflow-y: auto;
	overflow-x: hidden;
}

.Wraning {
	color: red;
}

.isshow {
	display: none;
}

.ivu-table-overflowX::-webkit-scrollbar-thumb {
	background-color: #103c56;
}

.mytable::-webkit-scrollbar-thumb {
	background-color: #103c56;
}

.ivu-checkbox-checked .ivu-checkbox-inner {
	border-color: #103c56 !important;
	background-color: #103c56 !important;
}

.ivu-checkbox-indeterminate .ivu-checkbox-inner {
	border-color: #103c56 !important;
	background-color: #103c56 !important;
}

.ivu-page-item:hover {
	color: white !important;
	border-color: #103c56 !important;
	background-color: #103c56 !important;
}

.ivu-page-item {
	border-color: #103c56 !important;
}

.ivu-page-item-active a, .ivu-page-item-active:hover a {
	color: white !important;
}

.ivu-page-item:hover a {
	color: white !important;
}

.ivu-page-item-active {
	color: white !important;
	border-color: #103c56 !important;
	background-color: #103c56 !important;
}

.ivu-table th {
	height: 40px;
	white-space: nowrap;
	overflow: hidden;
	background-color: transparent;
	color: white;
}

.ivu-table td {
	background-color: transparent;
}

.ivu-table-filter i.on {
	color: dodgerblue;
}

.ivu-table-filter i {
	color: white;
}

.ivu-page-next:hover, .ivu-page-prev:hover {
	border-color: #103c56;
}

.ivu-table-cell .ivu-date-picker {
	color: white;
}

.ivu-table-wrapper {
	border: none;
}

.Epititle {
	color: white;
	padding: 0 15px;
	line-height: 40px;
	border-bottom: 1px solid white;
}

.facelist {
	height: 100%;
	font-size: 14px;
}

.facebox {
	width: 140px;
	height: 140px;
	border-radius: 50%;
	border: 1px solid #103c56;
	overflow: hidden;
}

.facebox img {
	width: 100%;
	display: block;
	margin: 0 auto;
}

.activebox {
	animation: mybig infinite 2s;
	box-shadow: 0 1px 4px 2px red;
}

@keyframes mybig {
	0% {
		transform: scale(0.5)
	}
	100% {
		transform: scale(1)
	}
}

.content-title {
	font-size: 14px;
	color: white;
}

.right-content {
	width: 98%;
	margin:  0 auto;
	background-color: rgba(2, 30, 43, 0.2);
	border: 1px solid #226B92;
	border-radius: 5px;
	padding: 10px;
}

.right-content:after {
	content: '';
	display: block;
	clear: both;
}

.right-content > div {
	float: left;
	display: block;
}

.faceRow {
	width: 100%;
	user-select: none;
	white-space: nowrap;
	overflow: hidden;
	overflow-x: auto;
}

.faceRow > div {
	width: 70px;
	margin: 0 10px;
	padding: 5px;
	height: 100px;
	text-align: center;
	display: inline-block;
	cursor: pointer;
}

.active {
	background: rgba(38,121,163,.6);
	border: 1px solid rgba(38,121,163,.9);
}

